<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家資料管理《店家》</li>
            <li class="breadcrumb-item active" aria-current="page">店家資料維護</li>
            <li class="breadcrumb-item active" aria-current="page">店家資料編輯</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" :validation-schema="schema" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form">
                <div class="mb-3">
                    <label for="store_code" class="form-label">店家編號</label>
                    <input type="text" class="form-control" id="store_code" v-model="store_data.store_code" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="tax_id" class="form-label">統一編號</label>
                    <Field name="tax_id" v-slot="{ meta, field }" v-model="store_data.tax_id">
                        <input
                            id="tax_id"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="(store_data.origin_status !== null && store_data.origin_status !== undefined) && (store_data.origin_status === 0 || store_data.origin_status === 1 || store_data.origin_status === 5)"
                        />
                    </Field>
                    <ErrorMessage name="tax_id" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="store_name" class="form-label"><span class="required">*</span>店家名稱</label>
                    <Field name="store_name" v-slot="{ meta, field }" v-model="store_data.store_name">
                        <input
                            id="store_name"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="store_name" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="store_name_en" class="form-label"><span class="required">*</span>店家名稱（英文）</label>
                    <Field name="store_name_en" v-slot="{ meta, field }" v-model="store_data.store_name_en">
                        <input
                            id="store_name_en"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="store_name_en" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label class="form-label"><span class="required">*</span>是否開立收據 （開立發票之店家請點「否」）</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <Field name="invoice_options" v-slot="{ meta, field }" v-model="store_data.is_invoice">
                                <input
                                    id="invoice_radio_1"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="1"
                                    :checked="store_data.is_invoice === 1"
                                    :disabled="(store_data.origin_status !== null && store_data.origin_status !== undefined) && (store_data.origin_status === 0 || store_data.origin_status === 1 || store_data.origin_status === 5)"
                                />
                            </Field>
                            <label class="form-check-label" for="invoice_radio_1">是</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <Field name="invoice_options" v-slot="{ meta, field }" v-model="store_data.is_invoice">
                                <input
                                    id="invoice_radio_2"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="0"
                                    :checked="store_data.is_invoice === 0"
                                    :disabled="(store_data.origin_status !== null && store_data.origin_status !== undefined) && (store_data.origin_status === 0 || store_data.origin_status === 1 || store_data.origin_status === 5)"
                                />
                            </Field>
                            <label class="form-check-label" for="invoice_radio_2">否</label>
                        </div>
                    </div>
                    <ErrorMessage name="invoice_options" class="radio-invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="store_type" class="form-label"><span class="required" v-if="(store_data.origin_status === null || store_data.origin_status === undefined) || (store_data.origin_status === 4)">*</span>店家類型</label>
                    <Field name="store_type" v-slot="{ meta, field }" v-model="store_data.store_type">
                        <select
                            id="store_type"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            @change="onChangeStoreType($event.target.value)"
                            :disabled="(store_data.origin_status !== null && store_data.origin_status !== undefined) && (store_data.origin_status === 0 || store_data.origin_status === 1 || store_data.origin_status === 3 || store_data.origin_status === 5)"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in store_type_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="store_type" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="store_subtype" class="form-label">店家子類型</label>
                    <Field name="store_subtype" v-slot="{ meta, field }" v-model="store_data.store_subtype">
                        <select
                            id="store_subtype"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="(store_data.origin_status !== null && store_data.origin_status !== undefined) && (store_data.origin_status === 0 || store_data.origin_status === 1 || store_data.origin_status === 3 || store_data.origin_status === 5)"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in store_subtype_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="store_subtype" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="town" class="form-label"><span class="required">*</span>行政區</label>
                    <Field name="town" v-slot="{ meta, field }" v-model="store_data.town">
                        <select
                            id="town"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in town_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="town" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="address" class="form-label"><span class="required">*</span>店家地址</label>
                    <Field name="address" v-slot="{ meta, field }" v-model="store_data.address">
                        <input
                            id="address"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <div id="address_help" class="form-text text-start">「巷、弄、號前請填寫數字，如1巷2弄3號」</div>
                    <ErrorMessage name="address" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="address_en" class="form-label"><span class="required">*</span>店家地址（英文）</label>
                    <Field name="address_en" v-slot="{ meta, field }" v-model="store_data.address_en">
                        <input
                            id="address_en"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="address_en" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="lat" class="form-label">地圖緯度</label>
                    <input type="text" class="form-control" id="lat" v-model="store_data.lat" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="lng" class="form-label">地圖經度</label>
                    <input type="text" class="form-control" id="lng" v-model="store_data.lng" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="business_district" class="form-label"><span class="required">*</span>商圈</label>
                    <Field name="business_district" v-slot="{ meta, field }" v-model="store_data.business_district">
                        <select
                            id="business_district"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in business_district_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="business_district" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="phone" class="form-label"><span class="required">*</span>店家電話</label>
                    <Field name="phone" v-slot="{ meta, field }" v-model="store_data.phone">
                        <input
                            id="phone"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="phone" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="email" class="form-label"><span class="required">*</span>店家Email</label>
                    <Field name="email" v-slot="{ meta, field }" v-model="store_data.email">
                        <input
                            id="email"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="email" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="contact_person" class="form-label"><span class="required">*</span>聯絡人姓名</label>
                    <Field name="contact_person" v-slot="{ meta, field }" v-model="store_data.contact_person">
                        <input
                            id="contact_person"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="contact_person" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="contact_phone" class="form-label"><span class="required">*</span>聯絡人電話</label>
                    <Field name="contact_phone" v-slot="{ meta, field }" v-model="store_data.contact_phone">
                        <input
                            id="contact_phone"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="contact_phone" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="web_site" class="form-label">網站連結</label>
                    <Field name="web_site" v-slot="{ meta, field }" v-model="store_data.web_site">
                        <input
                            id="web_site"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="web_site" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="facebook" class="form-label">FB連結</label>
                    <Field name="facebook" v-slot="{ meta, field }" v-model="store_data.facebook">
                        <input
                            id="facebook"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="facebook" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="line_at_account" class="form-label">LINE@帳號</label>
                    <Field name="line_at_account" v-slot="{ meta, field }" v-model="store_data.line_at_account">
                        <input
                            id="line_at_account"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="line_at_account" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="instagram" class="form-label">IG連結</label>
                    <Field name="instagram" v-slot="{ meta, field }" v-model="store_data.instagram">
                        <input
                            id="instagram"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="instagram" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="forutek_url" class="form-label">旅行蹤APP網址</label>
                    <Field name="forutek_url" v-slot="{ meta, field }" v-model="store_data.forutek_url">
                        <input
                            id="forutek_url"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="forutek_url" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="business_time" class="form-label"><span class="required">*</span>營業時間</label>
                    <Field name="business_time" v-slot="{ meta, field }" v-model="store_data.business_time">
                        <textarea
                            id="business_time"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            rows="8"
                        />
                    </Field>
                    <ErrorMessage name="business_time" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="business_time_en" class="form-label">營業時間（英文）</label>
                    <Field name="business_time_en" v-slot="{ meta, field }" v-model="store_data.business_time_en">
                        <textarea
                            id="business_time_en"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            rows="8"
                        />
                    </Field>
                    <ErrorMessage name="business_time_en" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="introduce" class="form-label"><span class="required">*</span>店家簡介</label>
                    <Field name="introduce" v-slot="{ meta, field }" v-model="store_data.introduce">
                        <textarea
                            id="introduce"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            rows="8"
                        />
                    </Field>
                    <ErrorMessage name="introduce" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="introduce_en" class="form-label">店家簡介（英文）</label>
                    <textarea class="form-control" id="introduce_en" rows="8" v-model="store_data.introduce_en"></textarea>
                </div>
                <div class="mb-3">
                    <label for="image" class="form-label"><span class="required">*</span>上傳封面圖片</label>
                    <input type="file" class="form-control" id="image" @change="file_selected" accept=".jpg, .jpeg, .png" ref="image_input">
                    <div id="image_help" class="form-text">圖片建議解析度：774x558，檔案大小不可超過 5 MB，檔案格式：png、jpeg、jpg</div>
                </div>
                <div class="mb-3">
                    <img v-if="store_data.image_cover && image == null" :src="store_data.image_cover" width="774" height="558" style="width: 100%;"/> 
                    <img v-if="image" :src="image" width="774" height="558" style="width: 100%;"/> 
                </div>
                <div class="mb-3" v-if="$route.params.id !== undefined">
                    <label for="validated_time" class="form-label">審核通過時間</label>
                    <input type="text" class="form-control" id="validated_time" v-model="store_data.validated_time" :disabled="true">
                </div>
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
                <div class="mb-3">
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import { 
    get_store_detail,
    save_store_detail
} from '../../api/store';
import { mapActions, mapGetters } from 'vuex';
import { 
    get_store_all_option,
    get_store_subtype_option
} from '../../api/data';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { 
    store_create_rules,
    store_modify_rules,
} from '../../rules/store/store';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import { check_tax_id } from '../../api/tax_id';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            town_option: [],
            town_en_option: [],
            business_district_option: [],
            business_district_en_option: [],
            store_type_option: [],
            store_type_en_option: [],
            store_subtype_option: [],
            store_subtype_en_option: [],
            store_data: {
                tax_id: "",
                store_name: "",
                store_name_en: "",
                store_type: "",
                store_subtype: "",
                town: "",
                business_district: "",
                address: "",
                address_en: "",
                lng: "",
                lat: "",
                business_time: "",
                business_time_en: "",
                phone: "",
                email: "",
                contact_person: "",
                contact_phone: "",
                web_site: "",
                facebook: "",
                line_at_account: "",
                instagram: "",
                forutek_url: "",
                introduce: "",
                introduce_en: "",
                is_invoice: "",
                origin_status: null,
            },
            image: null,
            button_disabled: false,
            upload_image: null,
            can_go_back: false,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_store_detail({store_id: this.$route.params.id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'StoreList' });
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'StoreList' });
                } else {
                    this.store_data = response.data.response_data;
                    this.onChangeStoreType(response.data.response_data.store_type, true);
                }
            })
        },
        onChangeStoreType(store_type, init = false) {
            if (init === false) {
                this.store_data.store_subtype = "";
            }
            get_store_subtype_option({store_type: store_type}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.store_subtype_option = response.data.response_data["TW"];
                    this.store_subtype_en_option = response.data.response_data["EN"];
                }
            })
        },
        file_selected(e) {
            if (e.target.files.length != 0) {
                if ((e.target.files.item(0).size / 1024 / 1024).toFixed(2) > 5) {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            cancelButton: 'm-2 btn btn-danger'
                        },
                        buttonsStyling: false
                    })

                    swalWithBootstrapButtons.fire({
                        title: '錯誤訊息',
                        text: '檔案大小超過 5 MB 限制！',
                        icon: 'error',
                        cancelButtonText: '關閉',
                        showCancelButton: true,
                        showConfirmButton: false,
                        showCloseButton: true,
                        allowOutsideClick: false,
                    });
                    this.$refs.image_input.value = null;
                    this.image = null;
                    this.upload_image = null;
                } else {
                    const file = e.target.files.item(0);
                    const reader = new FileReader();
                    reader.addEventListener('load', this.image_loaded);
                    reader.readAsDataURL(file);
                    this.upload_image = e.target.files[0];
                }
            } else {
                this.$refs.image_input.value = null;
                this.image = null;
                this.upload_image = null;
            }
        },
        image_loaded(e) {
            this.image = e.target.result;
        },
        save() {
            this.button_disabled = true;
            if (this.store_data.tax_id !== "") {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                check_tax_id({tax_id: this.store_data.tax_id}).then(response => {
                    if (response.data.success === false && response.data.check === false) {
                        this.user_logout()
                    } else if (response.data.success === false && response.data.error_code === "1002") {
                        this.$router.push({ name: 'StoreList' });
                    } else if (response.data.success === false && response.data.error_code === "1004") {
                        this.button_disabled = false;
                        swalWithBootstrapButtons.fire({
                            title: '錯誤訊息',
                            text: "系統發生錯誤！",
                            icon: 'error',
                            cancelButtonText: '關閉',
                            showCancelButton: true,
                            showConfirmButton: false,
                            showCloseButton: true,
                            allowOutsideClick: false,
                        })
                    } else if (response.data.success === true && response.data.check === false) {
                        this.button_disabled = false;
                        swalWithBootstrapButtons.fire({
                            title: '錯誤訊息',
                            text: "查無稅籍編號登記於臺中市！",
                            icon: 'error',
                            cancelButtonText: '關閉',
                            showCancelButton: true,
                            showConfirmButton: false,
                            showCloseButton: true,
                            allowOutsideClick: false,
                        })
                    }  else if (response.data.success === true && response.data.check === true) {
                        this.save_data_after_check_tax_id();
                    }
                })
            } else {
                this.save_data_after_check_tax_id();
            }
        },
        save_data_after_check_tax_id() {
            this.button_disabled = true;
            let submit_store_data = JSON.parse(JSON.stringify(this.store_data));
            submit_store_data.image = null;
            let form_data = new FormData();
            form_data.append('store_data', JSON.stringify(submit_store_data));
            if (this.image !== null) {
                form_data.append('upload_image', this.upload_image, this.upload_image.name);
            }
            save_store_detail(form_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'StoreList' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "StoreList"});
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.id !== undefined) {
            this.search();
        }
        get_store_all_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.town_option = response.data.response_data.town["TW"];
                this.town_en_option = response.data.response_data.town["EN"];
                this.business_district_option = response.data.response_data.business_district["TW"];
                this.business_district_en_option = response.data.response_data.business_district["EN"];
                this.store_type_option = response.data.response_data.store_type["TW"];
                this.store_type_en_option = response.data.response_data.store_type["EN"];
            }
        })
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
        schema() {
            if (this.$route.params.id !== undefined) {
                return store_modify_rules;
            } else {
                return store_create_rules;
            }
        },
    }
};
</script>
